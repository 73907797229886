import React, { Component } from "react";

class Footer extends Component {
  render() {
    const subscribeAction = () => {
      window.location.href = "https://t.me/rosplat_official";
    };
    const signUpAction = () => {
      window.location.href = "https://lk.rosplat.store/";
    };
    return (
      <section className="subscribe">
        <div className="container">
          <div className="subscribe-wrapper">
            <div>
              <h2>Готовы начать с нами?</h2>
              <a href="#" name="gettingstarted"></a>
              <p className="subscribe-text">
                Зарегистрируйтесь и подписывайтесь на наш телеграме канал
              </p>
            </div>
            <div>
              <button className="btn subscribe-btn" onClick={signUpAction}>
                Зарегистрироваться
              </button>
              <br />
              <button className="btn subscribe-btn" onClick={subscribeAction}>
                Подписаться
              </button>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Footer;
