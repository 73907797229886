import React, { Component } from "react";
import Header from "./components/Header";
import Hero from "./components/Hero";
import Instructions from "./components/Instructions";
import About from "./components/About";
import Advantages from "./components/Advantages";
import Subscribe from "./components/Subscribe";
import Footer from "./components/Footer";
import "./styles/index.scss";
// import './main.css'

class App extends Component {
  render() {
    return (
      <>
        <Header />
        <Hero />
        <Instructions />
        <About />
        <Advantages />
        <Subscribe />
        <Footer />
      </>
    );
  }
}

export default App;
